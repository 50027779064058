import { ReactNode, ButtonHTMLAttributes } from "react";
import styles from "./styles.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  onClick: () => void;
  sticky?: boolean;
  id?: string;
  style?: { [key: string]: string };
  disabled?: boolean;
}

const Button = ({
  children,
  onClick,
  id,
  style,
  sticky,
  disabled,
  ...rest
}: ButtonProps) => {
  return (
    <button
      id={id}
      className={`${styles.btn} ${sticky && styles.sticky}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
